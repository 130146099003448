<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :elevation="0"
          color="#cb0c9f"
          class="
            font-weight-bolder
            btn-danger
            bg-gradient-info
            py-4
            px-8
            mt-auto
          "
          small
          v-bind="attrs"
          v-on="on"
        >
          AJOUTER
        </v-btn>
      </template>
      <v-card class="card-shadow border-radius-xl" id="basic">
        <UserForm
          @cancelForm="closeDialog"
          @updateData="updateData"
          :parkingList="parkingList"
          :userUpdate="userToUpdate"
          :key="dialog"
        ></UserForm>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserForm from "./UserForm.vue";

export default {
  name: "TriggUserForm",
  components: {
    UserForm,
  },

  props: {
    parkingList: {
      type: Array,
      required: true,
    },
    userToUpdate: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  watch: {
    userToUpdate(nVal) {
      if (nVal !== null) {
        this.dialog = true;
      }
    },
    dialog(nVal) {
      if (!nVal) {
        this.$emit("closeDialog");
      }
    },
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    updateData() {
      this.$emit("updateData");
    },
  },
};
</script>

<style scoped></style>
