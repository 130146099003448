<template>
  <div class="container">
    <v-row>
      <v-col cols="12">
        <v-card class="card-shadow border-radius-xl px-4 py-4 w-100">
          <v-row>
            <v-col cols="6">
              <h3>Liste des utilisateurs</h3>
              <p class="text-md mb-0 text-body">
                Ajouter, supprimer ou modifier des utilisateurs de Dynapark
              </p>
            </v-col>
            <v-col cols="6" class="table-options">
              <v-text-field
                v-model="search"
                rounded-sm
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                placeholder="Nom, email, plaque d'immatriculation, parking principal"
                class="
                  input-style
                  font-size-input
                  placeholder-lighter
                  input-alternative input-icon
                  border
                "
              >
                <template slot="prepend-inner">
                  <v-icon color="rgba(0,0,0,.6)" size=".875rem"
                    >fas fa-search</v-icon
                  >
                </template>
              </v-text-field>

              <TriggUserForm
                :parkingList="parkingList"
                :userToUpdate="userToUpdate"
                @closeDialog="refreshData"
                @updateData="updateData"
              ></TriggUserForm>

              <v-menu
                transition="slide-y-transition"
                offset-y
                offset-x
                min-width="300"
                max-width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    color="#fff"
                    class="font-weight-bolder btn-outline-default py-4 px-2"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    Autres actions
                  </v-btn>
                </template>

                <v-list class="py-0d">
                  <v-list-item
                    class="pa-4 list-item-hover-active"
                    @click="deleteUsers"
                  >
                    <v-list-item-title class="p-6"
                      >Supprimer {{ selectedUsers.length }} utilisateur{{
                        selectedUsers.length > 1 ? "s" : ""
                      }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="pa-4 list-item-hover-active"
                    @click="convertToXLS"
                  >
                    <v-list-item-title class="p-6"
                      >Exporter la table</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

          <v-row v-if="fetchingData">
            <div class="grid-center-h-v">
              <v-progress-circular
                :size="32"
                :width="2"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-row>
          <v-row v-else class="d-flex justify-end">
            <v-col cols="12">
              <UsersTable
                :users="filteredUsers"
                @updateUser="updateUser($event)"
                @selectedUsers="updateSelectedUsers($event)"
              ></UsersTable>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import UsersTable from "../../components/Dynapark/UsersTable.vue";
import TriggUserForm from "../../components/Dynapark/TriggUserForm.vue";

export default {
  name: "Users",

  components: {
    UsersTable,
    TriggUserForm,
  },

  data() {
    return {
      search: "",
      fetchingData: false,
      userList: [],
      parkingList: [],
      userToUpdate: null,
      selectedUsers: [],
    };
  },

  beforeMount() {
    this.fetchUsersParkings();
  },

  computed: {
    filteredUsers() {
      if (this.search === "") return this.userList;

      const dataToScan = [
        "name",
        "surname",
        "email",
        "immatList",
        "parkingAddress",
        "parkingName",
      ];
      return this.userList.filter((elm) => {
        return dataToScan.some((k) => {
          // -- Check array
          if (Array.isArray(elm[k])) {
            const immatMatch = elm[k].find((immat) =>
              immat.toLowerCase().includes(this.search.toLowerCase())
            );
            return immatMatch;
          }
          // -- Check string value
          return (
            elm[k] && elm[k].toLowerCase().includes(this.search.toLowerCase())
          );
        });
      });
    },
  },

  methods: {
    fetchUsersParkings() {
      // -- Fetch reservations and users to build a complete dataset
      this.fetchingData = true;
      const users = axios.get(`${this.$store.getters.apiurl}/users`);
      const parkings = axios.get(`${this.$store.getters.apiurl}/parkings`);
      axios
        .all([users, parkings])
        .then(
          axios.spread((...responses) => {
            this.fetchingData = false;
            const responseUsers = responses[0].data;
            const responseParkings = responses[1].data;
            this.parkingList = responseParkings;
            this.userList = this.buildUsersTable(
              responseUsers,
              responseParkings
            );
          })
        )
        .catch((errors) => {
          this.fetchingData = false;
          console.error(errors);
        });
    },

    buildUsersTable(users, parkings) {
      return users.map((user) => {
        const userParking = parkings.find(
          (parking) => parking.id === user.mainparking
        );
        const immatList = this.flatter(user.plates);
        return {
          fullname: `${user.surname} ${user.name}`,
          parkingName: userParking ? userParking.name : "n/c",
          parkingAddress: userParking
            ? `${userParking?.address} ${userParking?.city}`
            : "n/c",
          immatList,
          permissionName: user?.permissions?.name || "Aucune permission",
          checkbox: false,
          ...user,
        };
      });
    },

    updateUser(user) {
      this.userToUpdate = user;
    },

    updateSelectedUsers(usersIdSelected) {
      this.selectedUsers = usersIdSelected;
    },

    deleteUsers() {
      // -- Prevent an auto delete
      const currentUserId = this.$store.getters.userId;
      const safeUserList = this.selectedUsers.filter(
        (userId) => userId !== currentUserId
      );
      // -- Fetch API to delete user list
      const usersToDelete = safeUserList.map((id) => {
        return axios.delete(`${this.$store.getters.apiurl}/users/${id}`);
      });
      this.fetchingData = true;
      axios
        .all(usersToDelete)
        .then(
          axios.spread((...responses) => {
            console.info(`ℹ️ ${responses.length} deleted`);
            this.fetchUsersParkings();
          })
        )
        .catch((errors) => {
          this.fetchingData = false;
          console.error(errors);
        });
    },

    refreshData() {
      this.userToUpdate = null;
    },

    updateData() {
      this.fetchUsersParkings();
    },

    flatter(obj) {
      if (!obj) return [];
      let immat = [];
      Object.keys(obj).forEach((key) => {
        immat = [...immat, obj[key]];
      });
      return immat.flat();
    },

    convertToXLS() {
      console.log(this.filteredUsers);
      let spreadsheet = [];
      this.filteredUsers.forEach((user) => {
        let row = [
          user.name,
          user.surname,
          user.email,
          user.parkingName,
          user.parkingAddress,
          user?.permissions?.description ?? "",
          user?.permissions?.text ?? "",
        ];
        spreadsheet.push(row);
      });
      this.createExcelFile(spreadsheet, "Utilisateurs");
    },

    createExcelFile(dataToXls, title = "tableau", subject = "export") {
      let wb = XLSX.utils.book_new();
      wb.Props = {
        Title: title,
        Subject: subject,
        Author: "Dynapark",
        CreatedDate: new Date(),
      };
      wb.SheetNames.push(title);
      let ws_data = dataToXls;
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets[title] = ws;
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        `${title}-${subject}.xlsx`
      );
    },
  },
};
</script>

<style scoped>
.table-options {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}
</style>
