var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.users,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"fixed-header":"","height":"70vh","hide-default-footer":"","mobile-breakpoint":"0","items-per-page":_vm.users.length},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{attrs:{"ripple":false,"color":"#141727"},model:{value:(item.checkbox),callback:function ($$v) {_vm.$set(item, "checkbox", $$v)},expression:"item.checkbox"}})],1)]}},{key:"item.surname",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-sm font-weight-bold mt-4 mb-0"},[_vm._v(" "+_vm._s(item.fullname || "n/c")+" ")]),_c('p',{staticClass:"text-sm text-secondary"},[_vm._v(" "+_vm._s(item.email || "n/c")+" ")])]}},{key:"item.parkingName",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"text-sm text-secondary font-weight-bold mt-4 mb-0"},[_vm._v(" "+_vm._s(item.parkingName)+" ")]),_c('p',{staticClass:"text-sm text-secondary"},[_vm._v(" "+_vm._s(item.parkingAddress)+" ")])])]}},{key:"item.permissionName",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"text-sm text-secondary font-weight-bold mt-4 mb-0"},[_vm._v(" "+_vm._s(_vm.getDescription(item))+" ")]),_c('p',{staticClass:"text-sm text-secondary"},[_vm._v(" "+_vm._s(_vm.getText(item))+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-4 p-0 text-blue",staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){return _vm.updateUser(item)}}},[_vm._v(" Modifier ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }