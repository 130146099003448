<template>
  <div>
    <v-card-text class="px-0 py-0">
      <v-data-table
        :headers="headers"
        :items="users"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        fixed-header
        height="70vh"
        class="table"
        hide-default-footer
        mobile-breakpoint="0"
        :items-per-page="users.length"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="d-flex align-center">
            <v-checkbox
              v-model="item.checkbox"
              :ripple="false"
              color="#141727"
            ></v-checkbox>
          </div>
        </template>

        <template v-slot:[`item.surname`]="{ item }">
          <p class="text-sm font-weight-bold mt-4 mb-0">
            {{ item.fullname || "n/c" }}
          </p>
          <p class="text-sm text-secondary">
            {{ item.email || "n/c" }}
          </p>
        </template>

        <template v-slot:[`item.parkingName`]="{ item }">
          <div>
            <p class="text-sm text-secondary font-weight-bold mt-4 mb-0">
              {{ item.parkingName }}
            </p>
            <p class="text-sm text-secondary">
              {{ item.parkingAddress }}
            </p>
          </div>
        </template>

        <template v-slot:[`item.permissionName`]="{ item }">
          <div>
            <p class="text-sm text-secondary font-weight-bold mt-4 mb-0">
              {{ getDescription(item) }}
            </p>
            <p class="text-sm text-secondary">
              {{ getText(item) }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <p
            class="mt-4 p-0 text-blue"
            style="text-decoration: underline; cursor: pointer"
            @click="updateUser(item)"
          >
            Modifier
          </p>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "UsersTable",

  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      sortBy: "fullname",
      sortDesc: false,
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Utilisateur",
          value: "surname",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Parking principal",
          value: "parkingName",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Typologie de place",
          value: "permissionName",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "",
          value: "action",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },

  computed: {
    getSelected() {
      return this.users.filter((user) => user.checkbox);
    },
  },

  watch: {
    getSelected(nVal) {
      this.$emit(
        "selectedUsers",
        nVal.map((user) => user.id)
      );
    },
  },

  methods: {
    /**
     * GET DESCRIPTION
     * Permission description for P1, P2 ....
     *
     * @param {object} USER
     * @returns {string}
     */
    getDescription(user) {
      if (user && user.permissions && user.permissions.description) {
        return user.permissions.description;
      }
      return "Aucune permission";
    },

    /**
     * GET DESCRIPTION
     * Permission subtext,.
     *
     * @param {object} USER
     * @returns {string}
     */
    getText(user) {
      if (user && user.permissions && user.permissions.text) {
        return user.permissions.text;
      }
      return "L'utilisateur n'a pas de droit d'accès";
    },

    updateUser(user) {
      this.$emit("updateUser", user);
    },
  },
};
</script>

<style scoped></style>
