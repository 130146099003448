<template>
  <div>
    <div v-if="requestPending" class="grid-center-h-v">
      <v-progress-circular
        :size="32"
        :width="2"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="px-6 py-6">
        <h5 class="text-h5 font-weight-bold text-typo">
          COORDONNÉES UTILISATEUR
        </h5>
      </div>
      <div v-if="message" class="response-post">
        {{ message }}
        <v-btn
          elevation="0"
          color="#fff"
          class="font-weight-bolder btn-outline-default py-4 px-2 mt-4 mb-4"
          small
          @click="cancelForm"
          >FERMER</v-btn
        >
      </div>
      <div v-else>
        <div class="px-6 pb-6 pt-0">
          <!-- NAME - SURNAME -->
          <v-row>
            <v-col cols="6">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >NOM</label
              >
              <v-text-field
                v-model="surname"
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder=""
                class="
                  font-size-input
                  placeholder-lighter
                  border border-radius-md
                  text-light-input
                  mt-2
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >PRÉNOM</label
              >
              <v-text-field
                v-model="name"
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder=""
                class="
                  font-size-input
                  placeholder-lighter
                  border border-radius-md
                  text-light-input
                  mt-2
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- EMAIL -->
          <v-row :class="!(userUpdate == null) ? 'disabled-section' : ''">
            <v-col cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >EMAIL</label
              >
              <v-text-field
                v-model="email"
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder=""
                class="
                  font-size-input
                  placeholder-lighter
                  border border-radius-md
                  text-light-input
                  mt-2
                "
                :disabled="!(userUpdate == null)"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- DROIT UTILISATEUR - DROIT D'ACCES -->
          <v-row>
            <v-col cols="6">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >DROITS UTILISTEURS</label
              >
              <v-select
                v-model="userRight"
                :items="userRightList"
                item-text="name"
                item-value="value"
                color="rgba(0,0,0,.6)"
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                outlined
                single-line
                height="36"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >DROITS D'ACCÈS</label
              >
              <v-select
                v-model="accesRight"
                :items="accesRightList"
                item-text="name"
                item-value="id"
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                "
                outlined
                single-line
                height="36"
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item="{ item }">
                  <div style="padding: 1rem 0">
                    <p style="margin: 0">
                      {{ item.name }}
                    </p>
                    <small style="margin: 0">{{ item.description }}</small>
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- MAIN PARKING - SECONDARY PARKING -->
          <v-row>
            <v-col cols="6">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >PARKING PRINCIPAL</label
              >
              <v-select
                v-model="mainParking"
                :items="parkingList"
                item-text="name"
                item-value="id"
                hide-details
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                outlined
                single-line
                height="36"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >PARKING SECONDAIRE</label
              >
              <v-select
                v-model="secondaryParking"
                :items="secondaryParkingList"
                item-text="name"
                item-value="id"
                hide-details
                multiple
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                "
                outlined
                single-line
                height="36"
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- PREFERED SPOT -->
          <v-row>
            <v-col cols="6">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >PLACE PRÉFÉRÉE
              </label>
              <v-autocomplete
                v-model="favoriteSpot"
                :items="spotList"
                item-text="name"
                hide-details
                return-object
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                "
                outlined
                single-line
                height="26"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- AJOUTER UNE PLAQUE -->
          <v-row v-for="(_, idx) in plates" :key="idx">
            <v-col cols="3">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >TYPE DE VÉHICULE</label
              >
              <v-select
                v-model="plates[idx]['vehicle']"
                :items="vehicleList"
                value=""
                color="rgba(0,0,0,.6)"
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                outlined
                single-line
                height="36"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >IMMATRICULATION</label
              >
              <v-text-field
                v-model="plates[idx]['immat']"
                hide-details
                outlined
                color="rgba(0,0,0,.6)"
                light
                placeholder=""
                class="
                  font-size-input
                  placeholder-lighter
                  border border-radius-md
                  text-light-input
                  mt-2
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Attributs du véhicule</label
              >
              <v-select
                v-model="plates[idx]['attributes']"
                :items="attributeList"
                item-text="name"
                item-value="id"
                hide-details
                multiple
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                "
                outlined
                single-line
                height="36"
              >
              </v-select>
            </v-col>
            <v-col cols="1" class="d-flex align-center mt-8">
              <div class="cross" @click="deletePlate(idx)"></div>
            </v-col>
          </v-row>
          <v-btn
            elevation="0"
            color="#fff"
            class="font-weight-bolder btn-outline-default py-4 px-2 mr-4 mt-4"
            small
            @click="addPlate"
            >AJOUTER UNE PLAQUE</v-btn
          >
        </div>
        <div class="actions-btn">
          <v-btn
            elevation="0"
            color="#fff"
            class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
            small
            @click="cancelForm"
            >ANNULER</v-btn
          >
          <v-btn
            :elevation="0"
            color="#cb0c9f"
            class="
              font-weight-bolder
              btn-danger
              bg-gradient-info
              py-4
              px-8
              mt-auto
            "
            small
            :disabled="!canCreateUser"
            @click="createUser"
          >
            ENREGISTRER
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const VEHICLE_NAME_CONVERTER = {
  voiture: "car",
  moto: "moto",
  vélo: "bike",
  trottinette: "scooter",
  "mobilité douce": "soft",
};

export default {
  name: "UserForm",

  props: {
    parkingList: {
      type: Array,
      required: true,
    },
    userUpdate: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      requestPending: false,
      message: null,
      // -- USER DATA FORM
      name: null,
      surname: null,
      email: null,
      userRight: null,
      accesRight: null,
      mainParking: null,
      secondaryParking: null,
      spotList: [],
      favoriteSpot: null,
      plates: [],
      // -- SELECTORS
      accesRightList: [
        { description: "Place Attribuée", name: "P1", id: "p1" },
        { description: "Place Reservable", name: "P2", id: "p2" },
        { description: "Placement Libre", name: "P3", id: "p3" },
      ],
      userRightList: [
        { name: "administrateur", value: "admin" },
        { name: "utilisateur", value: "user" },
      ],
      vehicleList: Object.keys(VEHICLE_NAME_CONVERTER),
      attributeList: [],
      canGetSpotListField: false,
    };
  },

  beforeMount() {
    this.fetchAccess();
    this.fetchAttributList();
  },

  created() {
    if (this.userUpdate) {
      this.mapExistingUser(this.userUpdate);
    }
  },

  computed: {
    canCreateUser() {
      return (
        this.name &&
        this.surname &&
        this.userRight &&
        this.email &&
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
      );
    },

    secondaryParkingList() {
      return this.parkingList.filter(
        (parking) => parking.id !== this.mainParking
      );
    },

    getSpotListParams() {
      return `${this.mainParking}|${this.accesRight}`;
    },
  },

  watch: {
    userUpdate(nVal) {
      if (nVal && nVal.id) {
        this.mapExistingUser(nVal);
      }
    },

    getSpotListParams(nVal) {
      const [parkingId, accessId] = nVal.split("|");
      if (parkingId !== null && accessId !== null) {
        this.fetchSpotList(parkingId, accessId);
      }
    },
  },

  methods: {
    addPlate() {
      this.plates.push({ vehicle: "", immat: "", attributes: [] });
    },

    deletePlate(idx) {
      this.plates.splice(idx, 1);
    },

    cancelForm() {
      this.requestPending = false;
      this.message = null;
      this.name = null;
      this.surname = null;
      this.email = null;
      this.favoriteSpot = null;
      this.spotList = [];
      this.userRight = null;
      this.accesRight = null;
      this.mainParking = null;
      this.secondaryParking = null;
      this.plates = [];
      this.$emit("cancelForm");
    },

    mapExistingUser(user) {
      this.name = user.name || null;
      this.surname = user.surname || null;
      this.email = user.email || null;
      this.favoriteSpot = user.preferedspot || null;
      this.userRight = user.role || null;
      this.accesRight = user.priority || null;
      this.mainParking = user.mainparking || null;
      this.secondaryParking = user.secondaryparking
        ? user.secondaryparking
        : null;
      if (user.vehicles) {
        user.vehicles.forEach((v) => {
          this.plates.push({
            vehicle: this.keyValueSwitcher(VEHICLE_NAME_CONVERTER)[
              v.vehicletype
            ],
            immat: v.platenumber.startsWith("NOPLATE_") ? "" : v.platenumber,
            attributes: v.attributes,
          });
        });
      } else {
        if (user?.plates?.cars.length > 0) {
          user.plates.cars.forEach((immat) => {
            this.plates.push({ vehicle: "voiture", immat });
          });
        }
        if (user?.plates?.motorbike.length > 0) {
          user.plates.motorbike.forEach((immat) => {
            this.plates.push({ vehicle: "moto", immat });
          });
        }
      }
    },

    fetchAccess() {
      axios
        .get(`${this.$store.getters.apiurl}/permissions`)
        .then((res) => {
          this.accesRightList = res.data;
        })
        .catch((errors) => {
          console.error("Access right list ERROR:", errors);
        });
    },

    /**
     * Fetch spot list
     * @param {string} parkingId
     * @param {string} groupId
     */
    fetchSpotList(parkingId, groupId) {
      axios
        .get(
          `${this.$store.getters.apiurl}/parkingspots/${parkingId}?group=${groupId}`
        )
        .then((res) => {
          const spotList = res.data;
          if (Array.isArray(spotList) && spotList.length > 0) {
            this.canGetSpotListField = true;
            this.spotList = spotList;
          } else {
            this.canGetSpotListField = false;
            this.spotList = [];
          }
        })
        .catch((err) => {
          this.canGetSpotListField = false;
          this.spotList = [];
          console.error("GET SPOT LIST: ", err);
        });
    },

    fetchAttributList() {
      axios
        .get(`${this.$store.getters.apiurl}/attributes`)
        .then((res) => {
          this.attributeList = res.data;
        })
        .catch((errors) => {
          console.error("ERROR on fetch attributes list:", errors);
        });
    },

    getVehicleList() {
      if (this.plates.length == 0) {
        return [];
      }
      let vehicleList = [];
      this.plates.forEach((v) => {
        let car = {
          vehicletype: VEHICLE_NAME_CONVERTER[v.vehicle.toLowerCase()],
          platenumber: v.immat,
          attributes: v.attributes
            ? v.attributes.map((attr) => attr.id || attr)
            : [],
        };
        vehicleList.push(car);
      });
      return vehicleList;
    },

    createUser() {
      let platesCar = this.plates.filter(
        (elm) => elm.vehicle.toLowerCase() === "voiture"
      );
      platesCar = platesCar.map((elm) => elm.immat.trim().toUpperCase());

      let platesMoto = this.plates.filter(
        (elm) => elm.vehicle.toLowerCase() === "moto"
      );
      platesMoto = platesMoto.map((elm) => elm.immat.trim().toUpperCase());

      const user = {
        name: this.name.trim(),
        surname: this.surname.trim(),
        email: this.email.trim().toLowerCase(),
        role: this.userRight,
        status: this.userRight === "admin" ? "complete" : "incomplete",
        priority: this.accesRight || null,
        mainparking: this.mainParking || null,
        secondaryparking: this.secondaryParking ? this.secondaryParking : null,
        preferedspot: this.favoriteSpot?.name || this.favoriteSpot || null,
        preferedspotid: this.favoriteSpot?.id || null,
        plates: {
          cars: platesCar,
          motorbike: platesMoto,
        },
        vehicles: this.getVehicleList(),
      };
      for (const k in user) {
        if (user[k] === null) {
          delete user[k];
        }
      }
      this.requestPending = true;
      if (this.userUpdate && this.userUpdate.id) {
        axios
          .put(
            `${this.$store.getters.apiurl}/users/${this.userUpdate.id}`,
            user
          )
          .then(() => {
            this.message = `Utilisateur Mis à jour!`;
            this.requestPending = false;
            this.$emit("updateData");
          })
          .catch((err) => {
            this.message = "Erreur lors de la mise à jour de l'utilisateur";
            this.requestPending = false;
            console.error("CREATE USER ERROR: ", err);
          });
      } else {
        console.log("POST NEW USER: ", user);
        axios
          .post(`${this.$store.getters.apiurl}/users`, user)
          .then(() => {
            this.message = `Utilisateur crée`;
            this.requestPending = false;
            this.$emit("updateData");
          })
          .catch((err) => {
            this.message = "Erreur lors de la création de l'utilisateur";
            this.requestPending = false;
            console.error("CREATE USER ERROR: ", err);
          });
      }
    },

    keyValueSwitcher(obj) {
      const objArr = Object.entries(obj);
      let switched = {};
      objArr.forEach((kv) => {
        switched[kv[1]] = kv[0];
      });
      return switched;
    },
  },
};
</script>

<style scoped>
.cross {
  --b: 3px; /* the thickness*/
  --c: transparent 90deg, #999 0; /* the coloration */
  width: calc(var(--b) * 5); /* the size */
  aspect-ratio: 1/1;
  background: conic-gradient(from 90deg at var(--b) var(--b), var(--c))
    calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b))
    calc(50% + var(--b));

  display: inline-block;
  margin: var(--b);
  vertical-align: middle;
  transform: rotate(45deg);
}

.cross:hover {
  cursor: pointer;
}

.actions-btn {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
}

.response-post {
  text-align: center;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
</style>
